import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import StimulusReflex from 'stimulus_reflex';

import consumer from '../channels/consumer'; // ActionCable consumer
import controller from '../controllers/application_controller'; // Optional, if using a base controller

const application = Application.start();
const context = require.context('./', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

application.consumer = consumer;
StimulusReflex.initialize(application, { consumer, controller });

consumer.subscriptions.create("AppChannel", {
  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }
})
// console.log('Stimulus Reflex initialized successfully');

export { application }
