import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  connect() {
    super.connect()
    StimulusReflex.register(this)
  }

  static targets = ['modal', 'phone', 'fax', 'links', 'template', 'limbo']

  initialize() {
    Inputmask({ mask: '(999) 999-9999' }).mask(this.phoneTargets)
    Inputmask({ mask: '(999) 999-9999' }).mask(this.faxTargets)
  }

  new() {
    // console.log('new!')
  }

  add_lawyer(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    )
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_lawyer(event) {
    event.preventDefault()
    let wrapper = event.target.closest('.nested-fields')
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  launch_form(event) {
    event.preventDefault()
  }

  modalSuccess(event) {
    $('#caseModal').modal('show')
  }

  beforeReflex(element, reflex, noop, reflexId) {
    // Optional: Add any pre-reflex logic here
  }

  reflexSuccess(event) {
    const { operations } = event.detail;
    console.log('Reflex success:', operations);

    if (Array.isArray(operations)) {
      const batches = {};
      operations.forEach(operation => {
        if (!!operation.batch) {
          batches[operation.batch] = batches[operation.batch] ? ++batches[operation.batch] : 1;
        }
      });
      console.log('Batches:', batches);
    } else {
      console.error('Expected operations to be an array, but got:', operations);
    }
  }

  reflexError(event) {
    console.error('Reflex error:', event.detail);
  }
}