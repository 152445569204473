import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import CableReady from 'cable_ready'
import consumer from "../channels/consumer"
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import StimulusReflex from 'stimulus_reflex'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.consumer = consumer
StimulusReflex.initialize(application, { debug: true })

consumer.subscriptions.create("AppChannel", {
  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }
})

require('packs/bootstrap-wizard')
require('packs/jquery.bootstrap.wizard')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../images', true)

// design
import 'jquery.easing'
import Inputmask from 'inputmask'
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import '../stylesheets/application.scss'

// custom calendar functions
import calendarEntryComposer from 'packs/calendar-custom'

// fullcalendar
import { Calendar, formatDate } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'

document.addEventListener('turbolinks:load', function () {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').on(
      'click',
      function () {
        if (
          location.pathname.replace(/^\//, '') ==
            this.pathname.replace(/^\//, '') &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash)
          target = target.length
            ? target
            : $('[name=' + this.hash.slice(1) + ']')
          if (target.length) {
            $('html, body').animate(
              {
                scrollTop: target.offset().top - 66,
              },
              1000,
              'easeInOutExpo'
            )
            return false
          }
        }
      }
    )

    $('tr.clickable-row').on('click', function () {
      window.location = $(this).data('href')
    })

    $('#wizard').bootstrapWizard({
      onTabShow: function (tab, navigation, index) {
        var $total = navigation.find('li').length
        var $current = index + 1
        var $percent = ($current / $total) * 100
        $('#rootwizard .progress-bar').css({ width: $percent + '%' })
      },
    })

    const calendarEl = document.getElementById('calendar')

    //deprecated?
    const generalSlots = ['all_day', 'morning', 'afternoon']

    // each even below should be per day and list slots taken
    let calendar = new Calendar(calendarEl, {
      height: 'auto',
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',
      weekends: false,
      eventOrder: ['Full Day', 'Full Morning 9a-1p', 'Full Afternoon 1p-5p'],
      events: '/cases/events',
      showNonCurrentDates: false,
      // eventContent: { html: '<div class="fc-daygrid-event-dot"></div><div class="fc-event-time">8am-10am</div><div class="fc-daygrid-event-dot"></div><div class="fc-event-time">10am-12pm</div><div class="">full morning</div><div class="">1pm-3pm</div><div class="">3pm-5pm</div><div class="">full afternoon</div><div class="">all day</div>'}
      eventContent: function (arg) {
        let arrayOfDomNodes = []
        ;[1, 4, 0].forEach((element) => {
          $.merge(
            arrayOfDomNodes,
            calendarEntryComposer(
              arg.event.start,
              element,
              arg.event.extendedProps.segmentsReserved
            )
          )
        })
        return { domNodes: arrayOfDomNodes }
      },
    })
    calendar.render()

    // todo: move to stimulus controller case_controller.js
    // $('#caseModal').on('show.bs.modal', function (event) {
    //   const button = $(event.relatedTarget) // Button that triggered the modal
    //   let longDate = formatDate(button.data('case-on'), {
    //     month: 'short', year: 'numeric', day: 'numeric', timeZone: 'short', timeZone: 'local'
    //   })
    //   const modal = $(this)
    //   modal.find('.modal-title').text('Mediation Request: ' + longDate + ' ' +button.data('day-part'))
    //   modal.find('#case_scheduled_on').val(button.data('case-on'))
    // })

    $('.fc-event-main').each(function () {
      let dayPart = $(this).find('.fc-event-main-frame').data('day-part')
      let caseOn = $(this).find('.fc-event-main-frame').data('case-on')
      $(this).on('click', function () {
        $('#case_time_slot').val('all_' + dayPart.toLowerCase())
        $('#case_scheduled_on').val(caseOn)
        $('#case_form_subtitle').html(caseOn + ' ' + dayPart)
        // $(document).scrollTop( $('section#case').offset().top )
      })
    })
  })
})
import 'controllers'
import { isNumeric } from 'jquery'
import "config"
